import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useState } from "react";
import FadeIn from "react-fade-in";
import { ImLocation } from "react-icons/im";
import { BiLogoGmail } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa";
import { FaHeart } from "react-icons/fa";
import companyLogo from "../src/assets/VroTech_logo.png";
import BackgroundImage from "../src/assets/BackroundImage.jpg";
import WelcomeVrotech from "../src/assets/Welcome-Vrotech.jpg";

import arnoldFrancisca from "../src/assets/arnold-francisca-f77Bh3inUpE-unsplash.jpg";
import patrickPerkins from "../src/assets/patrick-perkins-ETRPjvb0KM0-unsplash.jpg";
import reactNativeLogo from "../src/assets/logo192.png";
import webDesign from "../src/assets/web-design-technology-browsing-programming-concept.jpg";
import reactJS from "../src/assets/pexels-wdnet-230544.jpg";

import digitalMarking from "./assets/Digital-MKarketing.jpg";

// import BackgroundImage from '../src/assets/BackgroundImage.jpeg';
import photoProfile from "../src/assets/SanjSahota.jpg";
function App() {
  const [HeaderItems, setHeaderItems] = useState(true);
  const [isSidebar, setIsSidebar] = useState(false);
  function showSidebar() {
    setIsSidebar(!isSidebar);
  }
  return (
    <div className="App">
      <FadeIn>
        <nav>
          <ul
            className="sidebar "
            style={{ display: isSidebar ? "flex" : "none" }}
          >
            <li onClick={() => showSidebar()}>
              <a>
                <svg
                  className="linkClose"
                  // style={{backgroundColor:'red', color:'red'}}
                  // color="red"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#e8eaed"
                >
                  <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                </svg>
              </a>
            </li>
   
            <li onClick={() => showSidebar()} className="link">
              <a href="/">Home</a>
            </li>
            <li onClick={() => showSidebar()} className="link">
              <a href="#AboutOurCompany">About Our Company</a>
            </li>
            <li onClick={() => showSidebar()} className="link">
              <a href="#OurServices">Services</a>
            </li>
            <li onClick={() => showSidebar()} className="link">
              <a href="#ContactUs">Contact Us</a>
            </li>
          </ul>
          <ul>
            <li>
              <div className="nav-logo">
                <img src={companyLogo} className="App-logo" alt="logo" />

                <h1 style={{ color: "#000", fontWeight: "bold" }}>Vro</h1>
                <h1
                  style={{
                    color: "#208fc7",
                    fontWeight: "bold",
                  }}
                >
                  Tech
                </h1>
              </div>
            </li>
            <li className="hideOnMobile">
              <a href="/">Home</a>
            </li>
            <li className="hideOnMobile">
              <a href="#AboutOurCompany">About Our Company</a>
            </li>
            <li className="hideOnMobile">
              <a href="#OurServices">Services</a>
            </li>
            <li className="hideOnMobile">
              <a href="#ContactUs">Contact Us</a>
            </li>
            <li onClick={() => showSidebar()} className="hideOnWindow">
              <a className="openBar">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#e8eaed"
                >
                  <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
        <div className="headerdiv" />
        <div
          id="home"
          className="WelcomeCon"
          style={{
            backgroundImage: `url(${WelcomeVrotech})`,
          }}
        >
          <h1>Welcome to Vrotech</h1>
          <p>Your trusted partner in technology solutions.</p>
          {/* <div style={{alignItems:'flex-end',display:'flex', backgroundColor:'red', width:'100%' }}>

          <h2>Innovating Your Digital Future</h2>
          </div> */}
        </div>
      </FadeIn>

      <FadeIn>
        <div
          className="Basic-detail"
          style={{
            backgroundImage: `url(${BackgroundImage})`,
          }}
        >
          <div className="company-detail ">
            <h1>Digital creative Design, Websites, Applications and SEO</h1>
            <h2>
              We are dedicated to achieving success through innovation,
              utilizing cutting-edge technologies to deliver exceptional IT
              solutions. Our expertise in mobile development allows us to craft
              bespoke, end-to-end solutions tailored to meet your unique
              business needs. With a commitment to excellence, we transform
              challenges into opportunities, ensuring that every project exceeds
              expectations and drives meaningful results.{" "}
            </h2>
          </div>
          <div className="company-detail ">
            <div className="company-detail2">
              <div
                className="alvaro-Bernal"
                style={{
                  backgroundImage: `url(${arnoldFrancisca})`,
                }}
              />
            </div>
            <div id="AboutOurCompany" />
          </div>
        </div>
      </FadeIn>
      <FadeIn>
        <div className="main-container">
          <div className="container">
            <div className="Border-line">
              <div className="main-container3">
                <h1>About Our Company</h1>
                <p>
                  Founded in 2024, Vrotech is dedicated to delivering top-notch
                  technology solutions. Our expertise spans across mobile
                  applications, web development, web design, SEO, and digital
                  marketing. VroTech has consistently expanded its client base
                  and earned recognition, being featured in reputable
                  publications such as Computer Times, IT India, and Computer
                  World.
                </p>
                <p>
                  Under the leadership of Sanj Sahota, we strive for excellence
                  in every project. Our team is committed to providing the best
                  services to help your business grow.
                </p>
              </div>
            </div>
          </div>
          <div className="container ">
            <div
              style={{
                backgroundImage: `url(${patrickPerkins})`,
              }}
              className="white-background"
            >
              <p>
                <i>
                  Our skillful mobile development capabilities give the ultimate
                  end-to-end customized solutions to meet business needs. We
                  understand how important a role an excellent business website
                  plays in today's world. Every employee at Vrotech gives their
                  best to contribute to the growth and success of the company.
                  We are passionate about technology and driven by the desire to
                  achieve outstanding results for our clients.
                </i>
              </p>
            </div>
            <div id="OurServices" />
          </div>
        </div>
      </FadeIn>

      <div className="Services">
        <div className="Container5">
          <h1>Services We provide</h1>

          <div className="linediv" />
        </div>
        <div className="Main-Container5">
          <div className="Service">
            <img src={reactJS} className="imageStyel" alt="mobile" />

            <h1>Mobile Application</h1>
            <h2>
              We Develop mobile apps in android and iOS. We also connect these
              apps with the web-sites to give better performance and best
              results for our customers. We have customer all over the world.
            </h2>
          </div>
          <div className="Service">
            <img src={reactNativeLogo} className="imageStyel" alt="web" />
            <h1>Web Development</h1>
            <h2>
              We provide best web development services. Our team have ability to
              develop applications using various CMS like
              wordpress,Cake-Php,Magento, Big-Commerce, Open-cart etc. We also
              develop Application in Java, JEE etc.
            </h2>
          </div>
          <div className="Service">
            <img src={webDesign} className="imageStyel" alt="web" />
            <h1>Web designing</h1>
            <h2>
              We Provide best Web Designing services. We are having a team of
              experienced professionals of web designing and development. Due to
              good designs delivered by our team, new clients are joining us on
              an everyday basis.
            </h2>
          </div>
          <div className="Service">
            <img src={digitalMarking} className="imageStyel" alt="web" />
            <h1>Digital marketing</h1>
            <h2>
              We provide top-notch digital marketing services that drive
              results. Our team of experienced professionals excels in creating
              effective digital marketing strategies that help businesses grow
              and thrive online. Thanks to our innovative approaches and
              successful campaigns, we continue to attract new clients every
              day.
            </h2>
          </div>
        </div>
      </div>

      <div className="Container3">
        <h2>Sahil Sahota</h2>
        <div className="linediv" />
        <img src={photoProfile} className="profilePhoto" alt="Photo" />
        <h1>Sahil Sahota-Placed at BaseLine IT Development</h1>
        <p>
          <i>
            Studying React.js at VroTech has broadened my understanding and
            highlighted the significance of web development in today’s world.
            The faculty and staff are incredibly supportive and always available
            to address any questions. My experience here has greatly enhanced my
            enthusiasm for the subject.
          </i>
        </p>
      </div>
      <div className="emptySpace" id="ContactUs" />

      <div className="Container6">
        <div className="Sub-Container6">
          <div className="logoStyle">
            <img src={companyLogo} className="App-logo" alt="logo" />
            <a style={{ color: "#000", fontWeight: "bold" }}>Vro</a>
            <a
              style={{
                color: "#208fc7",
                fontWeight: "bold",
              }}
            >
              Tech
            </a>
          </div>

          <h3>
            We provide the best services with top-notch security. Trust and
            reliability are our core values, ensuring that your needs are met
            with the highest level of professionalism.
          </h3>
          <h4>
            Designed with <FaHeart /> by VroTech.
          </h4>
        </div>
        <div className="Sub-Container6">
          <h1>Contact Info:</h1>
          <div className="ContactInfo">
            <ImLocation className="IconStyle" style={{ color: "#fff" }} />
            <h1>India, Punjab</h1>
          </div>
          <div className="ContactInfo">
            <BiLogoGmail className="IconStyle" />
            <h1>vrotech2023@gmail.com</h1>
          </div>
          <div className="ContactInfo">
            <FaPhoneAlt className="IconStyle" />

            <h1>+91 7508952459</h1>
          </div>
          <div className="ContactInfo">
            <IoLogoWhatsapp className="IconStyle" />
            <h1>+91 7508952459</h1>
          </div>
          <div className="ContactInfo">
            <FaLinkedinIn className="IconStyle" />

            <a
              aria-current="page"
              href="https://www.linkedin.com/in/vrotech-321292318/"
            >
              linkedin.com/in
            </a>
          </div>
        </div>
        {/* <div className="Sub-Container6  emptyDiv"></div> */}
      </div>
    </div>
  );
}
// background-color: rgb(23, 101, 124);
export default App;
